.p-inputnumber, .p-inputtext, .p-dropdown  {
  width: 100% ;
  border-radius: 10px;
  border: 1px solid #D6D6D6;
  transition: border .3s ease-out;
  color: #000814;
}
.with-icon {
  .p-dropdown {
    padding-left: 35px;
  }
}
.p-inputtext[readonly] {
  background-color: #EEEEEE;
}
.footer__btn {
  border-radius: 30px;
  border: 1px solid #dee2e6;
  padding: 10px 20px;
}
.p-dropdown-header {
  background-color: #ffffff !important;
  padding: 15px 10px 0px 10px !important;
  border-bottom: 0px !important;

  .p-dropdown-filter {
    border: 0px;
    background-color: #FAF9F8;
    border-radius: 20px;
    padding: 14px 20px;
  }
  .p-dropdown-filter:focus {
    box-shadow: none;
  }
  .p-dropdown-filter-icon{
    width: 18px;
    height: 18px;
    margin-top: -.7rem
  }
}


/* START EDITOR INPUT STYLES */
  .p-editor-content {
    resize: vertical; /* Allow vertical resizing */
    overflow: auto; /* Enable scrollbars if content overflows */
    min-height: 60px; /* Set a minimum height */
    max-height: 600px; /* Set a maximum height to control resizing */
    height: 80px; /* Default initial height */
    border: 1px solid #ddd; /* Optional: Add a border for clarity */
    scrollbar-width: none; /* Hides scrollbar */
    -ms-overflow-style: none; /* IE and Edge */
  }

  .p-editor-content::-webkit-scrollbar {
    display: none; /* Hides scrollbar in WebKit browsers */
  }

  .p-editor-toolbar{
    background: #fff !important;
    border-bottom: unset !important;
  }

/* END EDITOR INPUT STYLES */


.hidden-radio .p-radiobutton-box,
  p-radiobutton.hidden-radio {
  display: none !important;
}

.p-dialog-title {
  font: normal normal normal 30px/37px Product Sans !important;
}

.p-dialog-header-icon:enabled:hover {
  border: 0px;
  background: transparent!important;
}
.p-dialog-header-icon:focus {
  box-shadow: none !important;
}
