/* ------------------------------------- */


/*               VARIABLES               */


/* ------------------------------------- */

:root {
  --icons-color: #242c42;
  --inputs-color: #f6f6f9;
  --page-title-space-start: 60px;
  --form-layout-space-start: 14px;
  --page-title-arrow-width: 16px;
  --page-title-arrow-space-end: 0.5rem;
}


/* ------------------------------------- */


/*               GLOBAL STYLES           */


/* ------------------------------------- */

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/Product Sans Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/Product Sans Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/Product Sans Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Cascadia Code';
  src: url('../fonts/Cascadia.ttf') format('truetype');
  font-style: normal;
}

body {
  font-family: Inter, 'Product Sans', sans-serif;
  font-weight: normal;
  font-size: 15px;
  margin: 0 auto;
}


/* Scrollbar Styles */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border: 2px solid #9c9ea5;
  background-color: #9c9ea5;
  border-radius: 30px;
}

::-webkit-scrollbar-track {
  background-color: #e2e2e2;
  border-radius: 30px;
}


/* Utility Classes */

a {
  text-decoration: none;
  color: #22162B;
}

ul {
  list-style: none;
  padding: 0;
}


/* No Scrollbar */

.no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.small-page-container,
.medium-page-container,
.large-page-container {
  margin: 0px auto;
  height: 100%;
  padding-block-start: 30px;
}

.small-page-container {
  max-width: 1110px;
}

.medium-page-container {
  max-width: 1380px;
}

.large-page-container {
  max-width: 1465px;
}

.page-title {
  font: normal normal bold 40px/48px Product Sans;
  padding-block-end: 30px;
  padding-block-start: 15px;
}

.page-small-title {
  font: normal normal normal 26px/32px Product Sans;
  padding-block-start: 15px;
}

.sticky-page-title {
  position: sticky;
  background: #ffffff;
  z-index: 3;
  top: 0px;
  left: 0;
}

.input-group {
  margin-block: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;

  & label {
    margin-bottom: 5px;
    padding-left: 3px;
    font-weight: 500;
  }
}

.tab-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 25px;
}

.inputs-row {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-block: 25px;
  .input-group {
    margin: 0px;
  }
}

.info-card-container {
  margin-top: 15px;
}

.characters-usage {
  font-size: 12px;
  color: #515151;
  text-align: right;
  padding: 15px 0px;
  font-family: Inter;
}
.characters-usage.exceeded-limit {
  display: flex;
  justify-content: space-between;
  .exceeded-limit-message {
    color: red;
  }
}

.progress-loading {
  position: fixed !important;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 0.35rem !important;
}

.body-content {
  height: 100%;
  scrollbar-gutter: stable;
  @media screen and (max-width: 1024px) {
    padding: 0px 10px;
  }
}

.deleted-item {
  opacity: .1;
  transition: all .4s ease-out;
}

.to-delete-item {
  opacity: .8;
  transition: all .4s ease;
  background-color: #ba0c2f1a !important;
}

.slider-indicators {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  font-size: 13px;
  color: #515151;
}

.danger-zone {
  background-color: #FFEBEB !important;
}

.danger-zone .info-group {
  justify-content: space-between;
}

.subtitle {
  font-size: 12px;
  color: #909090;
  margin: 5px 0;
}

.info-group,
.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.save-button {
  background-color: #09BC8A;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.section {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  background-color: #FAF9F8;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 15px;
  color: #222;
  &.columns-section {
    flex-direction: column;
    align-items: stretch;
  }
}

.section-header {
  flex: 1;
  max-width: 200px;
}

.section-label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.section-content {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.name-fields {
  display: flex;
  gap: 20px;
  width: 100%;
}

.field-input {
  width: 100%;
}

.input-label {
  display: block;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 600;
}

.sidebar .input-control{
    padding: 0px 5px;
}
.integration-actions-container button {
  width: 100%;
  height: 36px;
  border-radius: 8px;
  border: none;
  color: #fff;
  background: #030303;
  cursor: pointer;
}


.integration-actions-container .no-drop {
  cursor: no-drop;
  opacity: 0.6;
}

.input-label-with-note {
  display: flex;
  flex-direction: column;

  .instruction-label {
    font: normal normal normal 12px/15px Inter;
    opacity: 0.9;
    margin-bottom: 0 !important;
    display: inline-block;
    color: #515151!important;
  }
}


// Plans
.plan-name {
  transition: color 200ms;
  margin: 0;
  font-size: 0.9rem;
  color: var(--visual-color);
  &::before {
    transition: background-color 200ms;
    width: 1rem;
    height: 1rem;
    margin-bottom: -0.2rem;
  }
}
.select-plan-button {
  cursor: pointer;
  margin-top: 1rem;
  background-color: #000;
  transition: background-color 200ms;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  width: 100%;
  &:hover:not(:disabled) {
    background-color: #333;
  }
  &:disabled {
    background-color: #b2b2b2;
  }
  &.is-popular:not(:disabled) {
    &:hover {
      --popularGradientColor1: #cf1b2a;
      --popularGradientColor2: #2925b9;
    }
    background-color: none;
    transition: --popularGradientColor1 200ms, --popularGradientColor2 200ms;
    background-image: linear-gradient(
        200deg,
        var(--popularGradientColor1),
        var(--popularGradientColor2)
    );
  }
}
.assistant-skeleton-item {
  height: 120px !important;
  @media screen and (max-width: 1024px) {
    height: 420px !important;
  }
}

.ngx-file-drop__drop-zone--over {
  .upload_file_template_container {
    border: 1px dashed #017f5f !important;
    background: #017f5f1c !important;
  }
}


.badge {
  margin: auto 5px;
  @media screen and (max-width: 1024px) {
    margin: 0px 5px;
  }
  border-radius: 25px;
  font-size: 10px;
  color: white;
  right: 10px;
  padding: 0.3rem 0.4rem;
  line-height: 1;
  &.success-badge {
    background-image: linear-gradient(to right, #647ce3, #43a2d1)
  }

  &.danger-badge{
    background-image: linear-gradient(to right, #c94b4b, #4b134f);
  }

  &.coming-soon {
    background-image: linear-gradient(to right, #6366f1, #a855f7);
  }
}
