.sxw-theme {
  .p-button {
    background: #000000;
    border: 0px !important;
    border-radius: 20px;
    transition: background-color, border 0.2s ease-in-out;
    padding: 10px 20px;
  }
  .p-input-icon-right > .p-inputtext {
    padding-right: 80px !important;
    font-size: 14px;
  }
  .p-button:enabled:hover,
  .p-button:enabled:active {
    background-color: #00000095 !important;
  }
  .p-button:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #000000, 0 1px 2px 0 black !important;
  }

  a.back_btn,
  a.p-button,
  a.p-button:focus,
  a.p-button:enabled:active,
  a.p-button:enabled:hover {
    background: transparent;
    color: #22162b;
    font-weight: bold;
    border: 0px;
    box-shadow: none;
    justify-content: center;
    width: 100%;
  }

  .full-width-input {
    width: 100%;
    textarea,
    input {
      width: 100% !important;
      border-radius: 10px;
    }
  }

  .p-slider-handle {
    background: #000000 !important;
    border: #000000 !important;
    width: 20px !important;
    height: 20px !important;
  }
  .p-slider-handle:focus {
    box-shadow: none !important;
  }
  .p-slider {
    background: #d6d6d6 !important;
    height: 8px !important;
    border-radius: 10px !important;

    .p-slider-range {
      background: #030303 !important;
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
  }
}

.primary-theme {
  .p-button {
    padding: 10px 23px;
    color: #ffffff;
    font: normal normal normal 16px/20px Inter;
    background-color: #030303;
    border-radius: 20px;
    border: 1px solid #030303;
    position: relative;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.2s ease-in-out;

    span {
      text-align: center;
      width: 100%;
    }

    sxw-svg-icon {
      height: 24px;
      position: absolute;
      left: 20px;
      top: calc(50% - 12px);
    }
  }
  .p-button:enabled:hover,
  .p-button:enabled:active {
    background-color: #03030395 !important;
  }
  .p-button:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #030303, 0 1px 2px 0 black !important;
  }
}
.submit-theme {
  .p-button {
    background: #09bc8a 0% 0% no-repeat padding-box;
    border: 0px;
  }
  .p-button:enabled:hover,
  .p-button:enabled:active {
    background-color: #09bc8aa1 !important;
  }
}
.action-button {
  .p-button {
    background-color: white;
    color: #000814;
    border: 1px solid #a2a2a2 !important;
    cursor: pointer;
    border-radius: 24px;
    height: 32px;
    width: 32px;
    padding: 0px;

    span {
      text-align: center;
      width: 100%;
    }
    svg {
      margin-left: 5px;
    }
  }

  .p-button:enabled:hover,
  .p-button:enabled:active {
    background-color: #00000010 !important;
    color: black;
  }
  .p-button:enabled:focus {
    box-shadow: none !important;
  }
}
.wide-action-button {
  .p-button {
    padding: 0px 10px !important;
    width: auto !important;
  }
}
.action-button.danger,
sxw-button .danger {
  .p-button {
    background-color: white;
    color: #ba0c2f !important;
    border: 1px solid #a2a2a2 !important;
  }
  .p-button:enabled:hover,
  .p-button:enabled:active {
    background-color: #e24c4c !important;
    color: white !important;
    border: 1px solid #e24c4c !important;
  }
}
.delete-button {
  .p-button {
    background: #ffffff;
    color: #000000;
    border: 1px solid #d6d6d6;
    border-radius: 25px;
    transition: background-color, border 0.2s ease-in-out;
  }
  .p-button:enabled:hover,
  .p-button:enabled:active {
    background-color: #ed8177 !important;
  }
  .p-button:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #000000, 0 1px 2px 0 black !important;
  }
}
.rectangle-button {
  .p-button {
    border-radius: 10px;
    span {
    }
  }

  .p-button:enabled:focus {
    box-shadow: none !important;
  }
}

.secondary-button {
  .p-button {
    background-color: white;
    color: #000814;
    border-width: 1px;
    border: 1px solid #d6d6d6;
    cursor: pointer;
    border-radius: 15px;
    text-align: center;
    padding: 8px 20px;
  }
  .p-button:enabled:hover,
  .p-button:enabled:active {
    background-color: #00000095 !important;
  }
}

.color-code-theme .p-colorpicker-preview {
  width: 32px;
  height: 32px;
}

.p-component-overlay {
  z-index: 10 !important;
}

.discount-color {
  color: #0ec592;
}

.plan-name::before {
  content: "";
  filter: blur(0.2rem);
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--visual-color);
  border-radius: 50%;
  margin-right: 0.5rem;
  margin-bottom: -0.2rem;
}

.horizontal-nav {
  background: #fff;
  padding: 0.5rem;
  box-shadow: 0px 0px 20px 0px #efefef;
  border-radius: 2rem;
  display: inline-block;
  width: 100%;
  .nav-item {
    cursor: pointer;
    transition: color 200ms, background-color 200ms;
    background: #fff;
    padding: 0.5rem 1rem;
    color: #000;
    border-radius: 1rem;
    display: inline-block;
    font-size: 0.8rem;
    &:not(:last-of-type) {
      margin-right: 0.25rem;
    }
    &:hover,
    &.active {
      background: #000;
      color: #fff;
    }
    &.active {
      pointer-events: none;
    }
  }
}
.radio-btn {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  border: 1px solid #b4b4b4;
  border-radius: 33%;
  position: relative;
  background: #eee;
  &.selected::before {
    content: "";
    position: absolute;
    top: 15%;
    left: 15%;
    bottom: 15%;
    right: 15%;
    display: block;
    background: #333;
    border-radius: 33%;
    border: 1px solid #888;
  }
}

.section sxw-table.full-table {
  margin-right: -26px;
  margin-left: -20px;
  th:first-of-type,
  td:first-of-type {
    padding: 1rem 1rem 1rem 30px;
  }
  th:last-of-type,
  td:last-of-type {
    padding: 30px 1rem 1rem 1rem;
  }
}

sxw-table th {
  background: #f2f4f9 !important;
}

.side-view {
  color: #222;
  .header {
    font-size: 1.25rem;
  }
  .content > .row {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #eee;
    > .label {
      font-weight: 600;
    }
  }
}

.sidebar__content {
  padding: 5px;
}

.radio-image-theme {
  display: flex;
}

.prevent-inputs-padding sxw-text-input .input-control {
  padding: 0;
}

.manage-integration-action-theme button {
  width: 280px;
  height: 44px;
  font-size: 16px;
  font-weight: 600;
}

#cpk-chat-window {
  height: calc(95% - 5rem) !important;
}
@import "./thems/auth.scss";
