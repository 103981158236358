
.auth-title {
    text-align: left;
    margin: 10px 0;
    font-size: 14px !important;
    color: #000000 !important;
}

.auth-description {
    text-align: left;
    margin: 10px 0;
    font-size: 24px !important;
    color: #000000 !important;
}

.auth-logo img {
    display: block;
    margin: 0 auto 20px;
    width: 120px;
    height: 80px;
}

.oAuth-buttons {
    display: flex;
    gap: 10px;
    margin: 15px 0px 15px 0px;

    .google, .apple {
        flex: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 30px;
        font-size: 14px;
        cursor: pointer;
        color: #000000;
        text-align: center;
        border-width: 0px;
    }

    .google {
        background-color: #FAF9F8;
    }

    .apple {
        background-color: #FAF9F8;
    }

    .google i, .apple i {
        margin-right: 10px;
    }
}

.auth-separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: #D6D6D6;
}

.auth-separator::before,
.auth-separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ddd;
}

.auth-separator span {
    margin: 0 10px;
}

.input-label {
    display: block;
    font-size: 14px;
    margin-bottom: 4px;
}

.auth-bottom-action {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.auth-bottom-action p {
    margin: 0;
    font-size: 12px;
    color: #000814;
}

.auth-bottom-action a {
    text-decoration: none;
    color: #000000;
    text-decoration: underline;
}

.auth-submit-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    color: white;
}

.success-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    padding: 15px;
    margin: 10px 0;
    border-radius: 4px;
}

.password-mismatch-label{
    color: #B51313;
    font-weight: 500;
}

@media (max-width: 1060px) {
    .auth-submit-action {
      justify-content: center;
    }
}

.auth-input-row {
    @extend .inputs-row;
    margin-block: 10px;
    gap: 10px;
    display: flex;

    .auth-input-group{
        margin: 0px !important;
    }
}

.auth-input-group {
    @extend .input-group;
    margin-block: 10px;
}